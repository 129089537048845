import React from "react";
import { graphql } from "gatsby";
import CasePost from "../components/Case/CasePost";
import GraphQLErrorList from "../components/Case/graphql-error-list";
import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";

export const query = graphql`
  query CasePostTemplateQuery($id: String!) {
    post: sanityCases(id: { eq: $id }) {
      id
      youtube{
        url
      }

      publishedAt
      categories {
        _id
        title
      }
      headline
      blurb
      featuredImage {
        asset {
          gatsbyImageData
          url
        }
      }
      title
      slug {
        current
      }
      seoTitle
      metaDescription
      _rawContent(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      author {
        name
        image {
          asset {
            gatsbyImageData
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const CasePostTemplate = (props) => {
  const { data, errors } = props;
  const post = data && data.post;
  const site = data && data.site;
  return (
    <Layout>
      {errors && <SearchEngineOptimization title="GraphQL Error" />}
      {post && (
        <SearchEngineOptimization
          title={post.seoTitle}
          description={post.metaDescription}
          // openGraphImage={post.featuredImage.asset.url}
          // twitterOpenGraphImage={post.featuredImage.asset.url}
        />
      )}

      {errors && (
        <div className="container">
          <GraphQLErrorList errors={errors} />
        </div>
      )}

      {post && (
        <>
          <CasePost {...post} {...site} />
        </>
      )}
    </Layout>
  );
};

export default CasePostTemplate;
