import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import styled from "@emotion/styled";
import tw from "twin.macro";
// import Link from "gatsby-plugin-transition-link";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../../lib/helpers";
import PortableText from "./portableText";
// import Newsletter from "../Form/Newsletter";
import CasePostPreview from "./CasePostPreview";
import CallToAction from "../Repeating/CTA";
import ButtonSolid from "../Button/ButtonSolid";






const StyledContent = styled.div`
  p,
  span,
  li {
    ${tw`md:text-xl`}
  }
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
  }
  ol {
    ${tw`list-decimal pl-7 mb-6 flex flex-col space-y-0.5`}
  }
  strong {
    ${tw`font-bold`}
  }
  em {
    ${tw`italic`}
  }
`;

function CasePost(props) {
  const {
    siteMetadata,
    // next,
    // prev,
    slug,
    headline,
    // text,
    // content,    

    // _rawContent,
    _rawBody,
    blurb,
    author,
    // categories,
    title,
    // featuredImage,
    // publishedAt,
    youtube,

  } = props;

  const data = useStaticQuery(graphql`
    {
      posts: allSanityCases(
        sort: { fields: [publishedAt], order: DESC }
        filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      ) {
        edges {
          node {
            id

            headline
            blurb
            publishedAt
            featuredImage {
              asset {
                gatsbyImageData
              }
            }
          

            categories {
              _id
              title
            }
            title
            slug {
              current
            }
            _rawBody(resolveReferences: { maxDepth: 5 })
            _rawContent(resolveReferences: { maxDepth: 5 })
            youtube {
              url
            }
          }
        }
      }
    }
  `);

  // const currentPostCategory =
  //   categories.length > 0 &&
  //   categories.slice(0, 1).map((category) => category.title);

  const postNodes =
    data && data.posts
      ? mapEdgesToNodes(data.posts)
          .filter(filterOutDocsWithoutSlugs)
          .filter(filterOutDocsPublishedInTheFuture)
      : [];

  // categories.length > 0
  //   ? (postNodes = (data || {}).posts
  //       ? mapEdgesToNodes(data.posts)
  //           .filter(filterOutDocsWithoutSlugs)
  //           .filter(filterOutDocsPublishedInTheFuture)
  //           .filter((items) =>
  //             items.categories.find(
  //               (item) => item.title === currentPostCategory
  //             )
  //           )
  //       : [])
  //   : (postNodes = (data || {}).posts
  //       ? mapEdgesToNodes(data.posts)
  //           .filter(filterOutDocsWithoutSlugs)
  //           .filter(filterOutDocsPublishedInTheFuture)
  //       : []);

  return (
    <>
      <section className="mb-20 rounded-bl-[60px] bg-primary-900 pt-20 pb-20 md:mb-28 md:rounded-bl-[120px] md:pt-32 md:pb-28 lg:rounded-bl-[220px]">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-14 lg:gap-x-28">
            <div className="order-2 md:order-2">
              {/* {author && author.image && (
                <GatsbyImage
                  image={author.image.asset.gatsbyImageData}
                  loading="lazy"
                  className="z-0 h-12 w-12 rounded-full"
                />
              )} */}
              <h1 className="text-white">
                Case Study:<br></br> <b>{author.name}</b>
              </h1>

              <StyledContent>
                <p className="italic text-white/80 md:mb-8">{`" ${blurb} "`}</p>
                <div className="flex items-center space-x-2">
                  {author && author.image && (
                    <GatsbyImage
                      image={author.image.asset.gatsbyImageData}
                      loading="lazy"
                      className="z-0 h-12 w-12 rounded-full"
                    />
                  )}
                  <div>
                    {author && author.image && (
                      <div className="text-primary-200">
                        <b>{author.name}</b>
                      </div>
                    )}
                    {author && author.businessNameTitle && (
                      <div className="font-body text-sm font-bold uppercase tracking-wide text-primary-400">
                        {author.businessNameTitle}
                      </div>
                    )}
                  </div>
                </div>
              </StyledContent>
            </div>
            <div className="order-1 text-center md:order-1 md:text-left">
              <div className="mb-5 h-[250px] overflow-hidden rounded-xl md:h-[350px]">
                <iframe
                  className="mb-5 h-full w-full overflow-hidden rounded-xl"
                  title={`${headline}`}
                  src={`${youtube.url}`}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <article className="mb-20 pt-12 md:mb-32 md:pt-0">
        <div className="container">
          <div className="mx-auto max-w-[720px]">
            <StyledContent>
              <h2 className="mb-0 pb-5 text-center md:pb-14 md:text-left">
                {headline}
              </h2>
              <PortableText blocks={_rawBody} />
            </StyledContent>

            <div className="mb-24 mt-14 md:mb-36 md:mt-16">
              <div className="mb-2.5 text-center text-sm font-bold uppercase tracking-wider text-primary-900">
                Share
              </div>
              <div className="flex items-center justify-center space-x-6">
                <FacebookShareButton
                  url={`${siteMetadata.siteUrl}/${slug.current}/`}
                  quote={title}
                  className="no-underline"
                >
                  <i className="fab fa-facebook-f text-primary-800 !flex h-10 w-10 items-center justify-center rounded-full bg-primary-200 text-primary-900 transition-colors duration-300 ease-linear hover:bg-primary-900 hover:text-white"></i>
                </FacebookShareButton>

                <LinkedinShareButton
                  url={`${siteMetadata.siteUrl}/${slug.current}/`}
                  title={title}
                  className="no-underline"
                >
                  <i className="fab fa-linkedin-in text-primary-800 !flex h-10 w-10 items-center justify-center rounded-full bg-primary-200 text-primary-900 transition-colors duration-300 ease-linear hover:bg-primary-900 hover:text-white"></i>
                </LinkedinShareButton>

                <TwitterShareButton
                  url={`${siteMetadata.siteUrl}/${slug.current}/`}
                  title={title}
                  className="no-underline"
                >
                  <i className="fab fa-twitter text-primary-800 !flex h-10 w-10 items-center justify-center rounded-full bg-primary-200 text-primary-900 transition-colors duration-300 ease-linear hover:bg-primary-900 hover:text-white"></i>
                </TwitterShareButton>
              </div>
            </div>

            {/* <div className="mt-16 flex justify-between">
              {prev ? (
                <Link
                  
                  to={`/${prev.slug.current}/`}
                  className="group inline-flex items-center justify-center space-x-2.5 font-bold text-primary-400 hover:text-primary-400"
                >
                  <i className="far fa-arrow-left relative right-0 text-xl transition-all duration-300 ease-linear group-hover:right-1.5"></i>{" "}
                  <span>Previous</span>
                </Link>
              ) : (
                <div></div>
              )}
              {next ? (
                <Link
                  
                  to={`/${next.slug.current}/`}
                  className="group inline-flex items-center justify-center space-x-2.5 font-bold text-primary-400 hover:text-primary-400"
                >
                  <span>Next</span>
                  <i className="far fa-arrow-right relative left-0 text-xl transition-all duration-300 ease-linear group-hover:left-1.5"></i>
                </Link>
              ) : (
                <div></div>
              )}
            </div> */}
          </div>
        </div>
      </article>

      <section className="pb-20 md:pb-32">
        <div className="container">
          <div className="mb-8 flex items-center justify-between md:mb-10">
            <h2 className="mb-0">Recent Case Studies</h2>
            <ButtonSolid
              altStyle={3}
              href="/case-studies/"
              text="Read All Articles"
              className="hidden md:inline-flex"
            />
          </div>

          {postNodes && postNodes.length > 0 && (
            <div className="mb-10 grid gap-y-8 gap-x-6 md:mb-0 md:grid-cols-3 lg:gap-x-10">
              {postNodes.slice(0, 3).map((node) => (
                <div key={node.id}>
                  <CasePostPreview {...node} isInList />
                </div>
              ))}
            </div>
          )}

          {/* <ButtonSolid
            altStyle={3}
            href="/small-business-accounting-blog/"
            text="Read All Articles"
            className="md:hidden"
          /> */}
        </div>
      </section>

      <CallToAction />
    </>
  );
}

export default CasePost;
